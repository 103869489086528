.list-bullets > li {
    position: relative;
    margin: 0 0 0px 30px;
    padding: 0px 0px;
}

.width100Percent {
    width: 100% !important;
}

.job-info-box ul, .job-info-box ol {
    padding-left: 40px !important;
} 
