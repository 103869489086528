.loading-indicator {
    width: 100%;
    height: 4px;
    background-color: #f2f2f2;
    overflow: hidden;
    z-index: 9999999 + 1;
  }
  
  .subline {
    height: 8px;
    width: 100%;
    background-color: #ff0000;
    animation: traverse 2s linear infinite;
  }
  
  @keyframes traverse {
    0% {
      transform: translateX(-100%);
      background-color: #ff0000;
    }
  
    50% {
      background-color: #0000ff;
    }
  
    100% {
      transform: translateX(100%);
      background-color: #00ff00;
    }
  }
  
  .fetch-loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999;
  }