.width100Percent {
    width: 100% !important;
}

.job-bx-title {
    font-size: inherit !important;
}

.wysiwygEditor {
    background-color: white !important;
}

ul.handCursor button.nav-link {
    cursor: pointer !important;
    -moz-user-select: none;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 4px;
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    text-align: center;
    transition: all 0.2s ease-in-out 0s;
    vertical-align: middle;
    white-space: nowrap;
}

ul.handCursor button.nav-link.active {
    background-color: #0275d8;
    border-color: #0275d8;
    color: #ffffff;
}

@media only screen and (max-width: 767px) {
    div.creatableSelect {
        height: 55px !important;
    }
} 

#searchTextTags div.form-control.creatableSelect {
    border: none !important;
}