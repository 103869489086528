.post-job-bx .post-bx {
    padding: 15px !important;
}

.post-job-bx > li {
    margin-bottom: 5px !important;
}

.img-fill {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
}