.dez-bnr-inr .container {
    height: auto !important;
}

.dez-bnr-inr.dez-bnr-inr-md {
    height: auto !important;
}

.no-border {
    border: none !important;
}

.no-border-left {
    border-left: none !important;
}

.no-border-right {
    border-right: none !important;
}