.showOffersWithoutSalaryToggle {
    display: flex;
    align-items: center;
    text-align: right;
    position: absolute;
    right: 15px;
    font-weight: normal !important;
}

div.sliderOuterDiv {
    background: white !important;
}

div.sliderInnerDiv {
    top: 50% !important;
    -ms-transform: translateY(-50%) !important;
    transform: translateY(-50%) !important;
}

div.sliderTrackingDiv {
    margin-left: 35px !important;
    margin-right: 35px !important;
}

.accordion-header button.accordion-button {
    border: none;
    background: none;
}

.rc-slider-mark > span:nth-of-type(1) {
    transform: translate(0) !important;
    left: 0% !important;
}

.rc-slider-mark > span:nth-of-type(2) {
    transform: translate(-100%) !important;
    white-space: nowrap;
    left: 100% !important;
}

.react-toggle--checked .react-toggle-track {
    background-color: #abe2fb !important;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #666 !important;
}

 @media only screen and (max-width: 767px) {
    .job-search-form {
        bottom: inherit !important;
    } 

    .row-xs-100-percent-height {
        height: 100% !important;
    }

    .row-xs-60px-height {
        height: 60px !important;
    }
    
    div.categoriesSelect__indicator.categoriesSelect__clear-indicator,
    div.locationsSelect__indicator.locationsSelect__clear-indicator,
    div.seniorityLevelsSelect__indicator.seniorityLevelsSelect__clear-indicator {
        display: none !important;
    }

    div.creatableSelect {
        height: 55px !important;
    }
} 

@media only screen and (max-width: 1024px) {
    .job-search-form {
        bottom: inherit !important;
    } 

    .row-md-100-percent-height {
        height: 100% !important;
    }

    .row-md-60px-height {
        height: 60px !important;
    }
    
    div.categoriesSelect__indicator.categoriesSelect__clear-indicator,
    div.locationsSelect__indicator.locationsSelect__clear-indicator,
    div.seniorityLevelsSelect__indicator.seniorityLevelsSelect__clear-indicator {
        display: none !important;
    }
}

div.categoriesSelect__control div.categoriesSelect__value-container, 
div.locationsSelect__control div.locationsSelect__value-container,
div.seniorityLevelsSelect__control div.seniorityLevelsSelect__value-container {
    max-height: 38px !important; /* //todo: fudged! */
}

div.categoriesSelect__control.categoriesSelect__control--menu-is-open div.categoriesSelect__value-container, 
div.locationsSelect__control.locationsSelect__control--menu-is-open div.locationsSelect__value-container,
div.seniorityLevelsSelect__control.seniorityLevelsSelect__control--menu-is-open div.seniorityLevelsSelect__value-container {
    max-height: inherit !important;
}

div.categoriesSelect__control.categoriesSelect__control--menu-is-open, 
div.locationsSelect__control.locationsSelect__control--menu-is-open,
div.seniorityLevelsSelect__control.seniorityLevelsSelect__control--menu-is-open,
div.categoriesSelect__control.categoriesSelect__control--is-focused,
div.locationsSelect__control.locationsSelect__control--is-focused,
div.seniorityLevelsSelect__control.seniorityLevelsSelect__control--is-focused {
    z-index: 1000 !important;
}

div.form-control-height-inherited {
    height: inherit !important;
}

.no-border-left {
    border-left: none !important;
}

.no-border-right {
    border-right: none !important;
}